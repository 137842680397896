<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Producto">
              <b-form-input v-model="product.name" required></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Precio">
              <b-form-input
                v-model="product.price"
                required
                type="number"
                step="0.1"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-checkbox
              size="lg"
              v-model="product.is_package"
              >¿Paquete en venta rápida?</b-form-checkbox
            >
          </div>
        </div>
      </b-card-text>
      <template #footer>
        <b-button variant="primary" @click="saveProduct">Guardar</b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ProductCreate",
  data() {
    return {
      product: {}
    };
  },
  mounted() {
    this.getProduct();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Producto" },
      { title: "Crear producto" }
    ]);
  },
  methods: {
    makeToastVariant(message = "Éxito", variant = "success") {
      this.$bvToast.toast(message, {
        title: variant === "success" ? "Éxito" : "Ooops",
        variant: variant,
        solid: true
      });
    },
    getProduct() {
      if ("id" in this.$route.params) {
        ApiService.get(`product/${this.$route.params.id}`).then(res => {
          this.product = res.data;
        });
      }
    },
    saveProduct() {
      let url =
        "id" in this.$route.params
          ? `product/${this.$route.params.id}/`
          : "product/";
      let method =
        "id" in this.$route.params ? ApiService.patch : ApiService.post;

      method(url, this.product)
        .then(() => {
          this.makeToastVariant("Se ha creado el item correctamente");
          if (!("id" in this.$route.params)) {
            this.product = {};
          }
        })
        .catch(() => {
          this.$bvToast.toast("No se ha posido crear el producto", {
            title: `Ooops`,
            variant: "danger",
            solid: true
          });
        });
    }
  }
};
</script>
